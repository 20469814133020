@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

.CheckoutDeliveryOptions {
    margin-top: 80px;
    > ul {
        border-right: 1px solid $grey5;
        border-left: 1px solid $grey5;
        border-radius: 10px;
    }

    @include wide-desktop {
        width: 100%;
    }
}
