@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-pilot/src/style/abstract/variables';
@import '../../../../advox-pilot/src/style/abstract/mixins';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-pilot/src/style/abstract/button';

:root {
    --checkout-address-table-row-border-color: #{$grey5};
    --checkout-address-table-border-color: transparent;
    --checkout-address-table-border-color-active: #{$default-secondary-base-color};
    --checkout-address-table-th-color: #{$default-primary-base-color};
    --checkout-address-table-td-color: #{$black};
}
.CheckoutAddressTable {
    .KeyValueTable-Heading svg {
        display: block;
    }
    &-Button {
        border-radius: 7px;
        border: 1px solid var(--checkout-address-table-border-color);
        cursor: pointer;
        margin-bottom: 25px;

        &_isSelected {
            border: 1px solid var(--checkout-address-table-border-color-active);
        }

        table thead th {
            border-radius: 7px;
            padding: 14px 33px 14px 33px;
            letter-spacing: 0.07em;
            line-height: 140%;
            font-family: $font-primary;
            font-weight: bold;
            word-break: break-word;

            @include mobile {
                padding: 14px 17px 14px 13px;
            }
        }
        table tbody tr {
            border-color: var(--checkout-address-table-row-border-color);
        }
        table td,
        table th {
            font-size: 15px;
            line-height: 150%;
            font-family: $font-secondary;

            @include mobileAndTablet {
                min-width: 105px;
            }
        }

        table th {
            padding: 14px 0 14px 33px;
            color: var(--checkout-address-table-th-color);
            font-weight: 500;
            letter-spacing: 0.05em;
            min-width: 200px;

            @include mobile {
                padding: 14px 0 14px 17px;
            }
        }

        table td {
            padding: 14px 33px 14px 0;
            color: var(--checkout-address-table-td-color);

            @include mobile {
                padding: 14px 17px 14px 0;
            }
        }
    }
}
